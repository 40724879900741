import { Share } from '@capacitor/share';
import { filter } from 'rxjs/operators';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { ApiService } from 'src/app/service/api.service';
import * as XLSX from 'xlsx';
import { Directory, Filesystem } from '@capacitor/filesystem';
import write_blob from 'capacitor-blob-writer';
import { Platform } from '@ionic/angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { convertBlobToBase64, isWindows } from 'src/app/utils';
interface ProdutoRequest {
  codigo: string;
  descricao: string;
  categoria_descricao: string;
  quantidade_venda: number;
  custo_unitario: number;
  valor_venda_unitaria: string;
  total_custo: number;
  total_venda: number;
}

interface FiltroRequest {
  data_inicio: string;
  data_fim: string;
  qtde_registros: number;
  usuario_id: string;
  totalizar_por: 'vlr' | 'qtd';
  uf: string;
}
interface UFResponse {
  result: { uf: string }[];
}
@Component({
  selector: 'app-divina-produto-mais-vendidos',
  templateUrl: './divina-produto-mais-vendidos.component.html',
  styleUrls: ['./divina-produto-mais-vendidos.component.scss'],
})
export class DivinaProdutosMaisVendidosComponent implements OnInit {
  empresa: any = [];
  date = new Date();
  init_dash = true;
  endpoint = 'gen/rest/TSMSincronizar/';
  filtro_request: FiltroRequest = {
    usuario_id: localStorage.getItem('id'),
    data_inicio: this.date.toISOString().substring(0, 10),
    data_fim: this.date.toISOString().substring(0, 10),
    qtde_registros: 100,
    totalizar_por: 'vlr',
    uf: '',
  };
  filtro = {
    dataInicio: this.date,
    dataFim: this.date,
    mesAtual: new Date().getMonth(),
    semanaAtual: 0,
    dia: 0,
    selecionado: 'DIA',
  };
  filter_options = [5, 10, 15, 20, 30, 50, 100];
  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    public _snackBar: MatSnackBar,
    public platform: Platform,
  ) {}

  ngOnInit() {}
  ionViewWillEnter() {
    // this.();
    // this.getTodas();
  }
  resultado: { uf: string; produtos_valor_total: any[]; produtos_qtd_total }[] =
    [];
  async getRanking(event?) {
    // this.getTodas();
    this.init_dash = false;
    this.resultado = [];
    this.cdr.detectChanges();
    this.filtro_request.usuario_id = localStorage.getItem('id');
    this.filtro_request.data_fim = this.datePipe.transform(
      this.filtro.dataFim,
      'yyyy-MM-dd'
    );
    this.filtro_request.data_inicio = this.datePipe.transform(
      this.filtro.dataInicio,
      'yyyy-MM-dd'
    );
    let ufs = await this.getUfAtivas();

    // ufs.forEach( e => this.resultado.push({uf:e.uf,produtos_qtd_total:[],produtos_valor_total:[]}));
    this.init_dash = false;

    for (let index = 0; index < ufs.length; index++) {
      const uf = ufs[index].uf;
      try {
        const produtos_qtd_total = await this.getRankingProdutos(uf, 'qtd');
        const produtos_valor_total = await this.getRankingProdutos(uf, 'vlr');

        let ufIndex = this.resultado.findIndex((e) => e.uf == uf);
        if (ufIndex != -1) {
          this.resultado[ufIndex] = {
            uf: uf,
            produtos_qtd_total: produtos_qtd_total.map((e) => {
              if (e.quantidade_venda)
                e.quantidade_venda = Number(e.quantidade_venda);
              if (e.total_venda) e.total_venda = Number(e.total_venda);
              return e;
            }),
            produtos_valor_total: produtos_valor_total.map((e) => {
              if (e.quantidade_venda)
                e.quantidade_venda = Number(e.quantidade_venda);
              if (e.total_venda) e.total_venda = Number(e.total_venda);
              return e;
            }),
          };
        } else {
          this.resultado.push({
            uf: uf,
            produtos_qtd_total: produtos_qtd_total.map((e) => {
              if (e.quantidade_venda)
                e.quantidade_venda = Number(e.quantidade_venda);
              if (e.total_venda) e.total_venda = Number(e.total_venda);
              return e;
            }),
            produtos_valor_total: produtos_valor_total.map((e) => {
              if (e.quantidade_venda)
                e.quantidade_venda = Number(e.quantidade_venda);
              if (e.total_venda) e.total_venda = Number(e.total_venda);
              return e;
            }),
          });
        }
        this.cdr.detectChanges();
      } catch (error) {

      }
    }
    this.init_dash = true;
    this.cdr.detectChanges();
  }
  async getTodas() {
    this.init_dash = false;
    let uf = 'Todas UF';
    try {
      const produtos_qtd_total = await this.getRankingProdutos(
        '',
        'qtd',
        'ListarRankingProdutosGeral'
      );
      const produtos_valor_total = await this.getRankingProdutos(
        '',
        'vlr',
        'ListarRankingProdutosGeral'
      );
      let ufIndex = this.resultado.findIndex((e) => e.uf == 'Todas UF');
      if (ufIndex != -1) {
        this.resultado[ufIndex] = {
          uf: uf,
          produtos_qtd_total: produtos_qtd_total.map((e) => {
            if (e.quantidade_venda)
              e.quantidade_venda = Number(e.quantidade_venda);
            if (e.total_venda) e.total_venda = Number(e.total_venda);
            return e;
          }),
          produtos_valor_total: produtos_valor_total.map((e) => {
            if (e.quantidade_venda)
              e.quantidade_venda = Number(e.quantidade_venda);
            if (e.total_venda) e.total_venda = Number(e.total_venda);
            return e;
          }),
        };
      } else {
        this.resultado.unshift({
          uf: uf,
          produtos_qtd_total: produtos_qtd_total.map((e) => {
            if (e.quantidade_venda)
              e.quantidade_venda = Number(e.quantidade_venda);
            if (e.total_venda) e.total_venda = Number(e.total_venda);
            return e;
          }),
          produtos_valor_total: produtos_valor_total.map((e) => {
            if (e.quantidade_venda)
              e.quantidade_venda = Number(e.quantidade_venda);
            if (e.total_venda) e.total_venda = Number(e.total_venda);
            return e;
          }),
        });
      }
    } catch (error) {

    }
    this.init_dash = true;
  }
  async getRankingProdutos(
    uf,
    totalizar_por,
    end = 'ListarRankingProdutosEmpresaId'
  ) {
    let filtro: any = { ...this.filtro_request };
    filtro.uf = uf;
    filtro.totalizar_por = totalizar_por;
    return await this.api.post(this.endpoint + end, filtro).then((res) => {
      let idsJavisualizados = [];
      let resultado = [];
      res.result?.map((e) => {
        e.quantidade_venda = Number(
          e.quantidade_venda.toString().replace(',', '.')
        );
        e.total_venda = Number(e.total_venda.toString().replace(',', '.'));
        if (idsJavisualizados.findIndex((v) => v == e.codigo) == -1) {
          let result: ProdutoRequest[] = res.result.filter(
            (x) => x.codigo == e.codigo
          );
          idsJavisualizados = [
            ...idsJavisualizados,
            ...result.map((y) => y.codigo),
          ];
          let total_venda = result
            .map((y) => Number(y.total_venda.toString().replace(',', '.')))
            .reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              0
            );
          let quantidade_venda = result
            .map((y) => Number(y.quantidade_venda.toString().replace(',', '.')))
            .reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              0
            );
          e.quantidade_venda = quantidade_venda;
          e.total_venda = total_venda;
          resultado.push(e);
        }
      });
      return resultado;
    }).catch(e => {

      this._snackBar.open("Houve um Error! Tente Novamente Mais Tarde");
      throw new Error(e);
    });
    //   async (response: any) => {

    // ).finally(() => {
    //   setTimeout(() => {
    //     this.init_dash = true;
    //     this.cdr.detectChanges();

    //   }, 200);
    // });
  }
  async getUfAtivas() {
    return await this.api
      .get<UFResponse>(this.endpoint + 'ListarUfEmpresas')
      .then((res) => res.result);
    //   async (response: any) => {

    // ).finally(() => {
    //   setTimeout(() => {
    //     this.init_dash = true;
    //     this.cdr.detectChanges();

    //   }, 200);
    // });
  }
  returnPorcentage(qtd, total) {
    const sum = 100 / total;
    return (sum * qtd).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  }
  mesAtual = new Date().getMonth();

  proximo() {
    switch (this.filtro.selecionado) {
      case 'SEMANA':
        this.filtro.semanaAtual++;
        this.addSemana();
        break;
      case 'MES':
        this.filtro.mesAtual++;
        this.addMes();
        break;
      case 'DIA':
        this.filtro.dia++;
        this.addDia();
        break;
    }
  }

  anterior() {
    switch (this.filtro.selecionado) {
      case 'SEMANA':
        this.filtro.semanaAtual--;
        this.addSemana();
        break;
      case 'MES':
        this.filtro.mesAtual--;
        this.addMes();
        break;
      case 'DIA':
        this.filtro.dia--;
        this.addDia();
        break;
    }
  }

  addSemana() {
    this.filtro.selecionado = 'SEMANA';
    let inicio = moment().add(this.filtro.semanaAtual, 'weeks').day(0); // domingo desta semana
    let fim = moment().add(this.filtro.semanaAtual, 'weeks').day(6); // sábado desta semana
    this.filtro.dataInicio = new Date(inicio.toString());
    this.filtro.dataFim = new Date(fim.toString());
  }
  addMes() {
    this.filtro.selecionado = 'MES';
    let d = new Date();
    let anoC = d.getFullYear();
    let mesC = this.filtro.mesAtual;
    this.filtro.dataInicio = new Date(anoC, mesC, 1);
    this.filtro.dataFim = new Date(anoC, mesC + 1, 0);
  }
  addDia() {
    this.filtro.selecionado = 'DIA';
    let date = new Date();
    let emissaoDe = new Date();
    let emissaoAte = new Date();
    this.filtro.dataInicio = new Date(
      emissaoDe.setDate(date.getDate() + this.filtro.dia)
    );
    this.filtro.dataFim = new Date(
      emissaoAte.setDate(date.getDate() + this.filtro.dia)
    );
  }
  async exportexcel() {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    /* table id is passed over here */
    if (this.resultado.length == 0) return;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // for (let index = 0; index < this.resultado.length; index++) {
    //   const item = this.resultado[index];

    // }
    /* generate workbook and add the worksheet */

    /* table id is passed over here */
    for (let index = 0; index < this.resultado.length; index++) {
      const item = this.resultado[index];
      if (item.produtos_valor_total.length) {
        let element = document.getElementById('valor-total-' + item.uf);
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
        XLSX.utils.book_append_sheet(wb, ws, "VLR - "+item.uf);
      }
      if (item.produtos_qtd_total.length) {
        let element = document.getElementById('quantidade-total-' + item.uf);
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
        XLSX.utils.book_append_sheet(wb, ws, "QTD - "+item.uf );
      }
    }
    /* generate workbook and add the worksheet */

    /* save to file */
    // XLSX.writeXLSX(
    //   wb_quantidade_total,
    //   `QuantidadeTotal_${this.filtro_request.data_inicio}_-_${
    //     this.filtro_request.data_fim
    //   }.xlsx`
    // );

    if (
      (this.platform.is('mobile') ||
        this.platform.is('tablet') ||
        this.platform.is('ios') ||
        this.platform.is('android')) && (
          !this.platform.is('desktop')  &&
          !this.platform.is('mobileweb') && !isWindows()
        )
    ) {
      const excelBuffer: any = XLSX.writeXLSX(wb, {
        bookType: 'xlsx',
        type: 'array',
      });
      const excelData: Blob = new Blob([excelBuffer], {
        type: EXCEL_TYPE,
      });
      const base64:string = await convertBlobToBase64(excelData);

      Filesystem.requestPermissions();
      const result = await Filesystem.writeFile({
        path: `Abc_vendas_por_UFs_${this.filtro_request.data_inicio}_-_${this.filtro_request.data_fim}.xlsx`,
        data: base64,
        // directory: FilesystemDirectory.Documents,
        directory: Directory.Cache,

      }).then(async e => {
          await Share.share({
          text:"ABC Vendas UF",
          title: "ABC Vendas UF",
          url: e.uri
        })
      });
      // write_blob({
      //   path: `ValorTotal_${this.filtro_request.data_inicio}_-_${this.filtro_request.data_fim}.xlsx`,
      //   directory: Directory.Documents,
      //   blob: excelData,
      // })
      //   .then((x) => {
      //     this._snackBar.open("Arquivo Salvo na pasta Documentos");
      //   })
      //   .catch(function (e) {
      //     window.alert(e);
      //   });
      // const base64:string = await convertBlobToBase64(excelData);
      // Filesystem.requestPermissions()
      // const result = await Filesystem.writeFile({
      //   path: `ValorTotal_${this.filtro_request.data_inicio}_-_${this.filtro_request.data_fim}.xlsx`,
      //   data: base64,
      //   // directory: FilesystemDirectory.Documents,
      //   directory: Directory.Documents,

      // });
      // this._snackBar.open("Arquivo Salvo na pasta Documentos","ok",{
      //   duration: 1000,
      // });
      // const excelBufferQuantidade: any = XLSX.writeXLSX(wb_quantidade_total, {
      //   bookType: 'xlsx',
      //   type: 'array',
      // });
      // const excelDataQuantidade: Blob = new Blob([excelBufferQuantidade], {
      //   type: EXCEL_TYPE,
      // });
      // const base641:string = await convertBlobToBase64(excelDataQuantidade);
      // Filesystem.requestPermissions()
      // const result1 = await Filesystem.writeFile({
      //   path: `ValorTotal_${this.filtro_request.data_inicio}_-_${this.filtro_request.data_fim}.xlsx`,
      //   data: base641,
      //   // directory: FilesystemDirectory.Documents,
      //   directory: Directory.Documents,

      // });
      // this._snackBar.open("Arquivo Salvo na pasta Documentos","ok",{
      //   duration: 1000,
      // });
      // write_blob({
      //   path: `QuantidadeTotal_${this.filtro_request.data_inicio}_-_${this.filtro_request.data_fim}.xlsx`,
      //   directory: Directory.Documents,
      //   blob: excelDataQuantidade,
      // })
      //   .then((x) => {
      //     this._snackBar.open("Arquivo Salvo na pasta Documentos");
      //   })
      //   .catch(function (e) {
      //     window.alert(e);
      //   });
    } else {
      /* save to file */
      XLSX.writeFile(
        wb,
        `Abc_vendas_por_UFs_${this.filtro_request.data_inicio}_-_${this.filtro_request.data_fim}.xlsx`
      );
      /* save to file */
      // XLSX.writeFile(
      //   wb_quantidade_total,
      //   `QuantidadeTotal_${this.filtro_request.data_inicio}_-_${this.filtro_request.data_fim}.xlsx`
      // );
    }

  }
}
