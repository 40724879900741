import { ChangeDetectorRef, Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ApexChart, ApexNonAxisChartSeries, ApexOptions, ApexResponsive, ChartComponent } from 'ng-apexcharts';
import { ApiService } from '../service/api.service';
import moment from 'moment';
import { DatePipe, DOCUMENT } from '@angular/common';
import { ViewWillEnter } from '@ionic/angular';
interface Empresa {
  empresa: string,
  formaPgto: string,
  valorTotal: string,
  numVendas: string,
  ticketMedio: string,
}
interface TicketMedio {
  formaPgto: string,
  ticketMedio: string,
}

interface FiltroRequest {
  idusuario: string;
  dataInicio: string;
  dataFim: string;
  pro: boolean;
}
@Component({
  selector: 'app-forma-pagamento',
  templateUrl: './forma-pagamento.component.html',
  styleUrls: ['./forma-pagamento.component.scss'],
})
export class FormaPagamentoComponent implements OnInit, ViewWillEnter {
  apexChartOptions: ApexOptions = {
    chart: {
      height: 500,
      width: "100%",
      type: 'pie',
      animations: {
        speed: 400,
      },
    },
    labels: [],
    series: [],
    legend: {
      // position: 'bottom',
      width: 400,
      horizontalAlign: 'left',
      fontSize: '14px',
      offsetX: 0,
      itemMargin: {
        horizontal: 0,
        vertical: 0
      },
      formatter: (seriesName, opts) => {
        const round = (num, places) => {
          return +(parseFloat(num).toFixed(places));
        }
        // const class3w = (index) => {
        //   if (index % 2) {
        //     return '<span style="background-color:#dedede; padding: 10px 0; border-radius:2px; line-height: 2.5">';
        //   } else {
        //     return '<span >';
        //   }
        // }
        return `<span>${opts.w.globals.series[opts.seriesIndex].toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' }) + " | " + round(opts.w.globals.seriesPercent[opts.seriesIndex], 2) + '%' + " | " + seriesName}
                      </span>`
      },
    },
    title: {
      text: undefined,
      align: 'left',
      margin: 20,
      offsetX: 50,
      offsetY: 0,
      style: {
        fontSize: '25px',
        fontWeight: 'bold',
        fontFamily: 'inherit',
        color: '#303030'
      },
    },
    subtitle: {
      text: undefined,
      align: 'left',
      margin: 20,
      offsetX: 50,
      offsetY: 370,
      style: {
        fontSize: '18px',
        fontWeight: 'normal',
        fontFamily: 'inherit',
        color: '#303030'
      },
    },
    plotOptions: {
      pie: {
        customScale: 0.8,
        expandOnClick: true,
        offsetX: -100,
        offsetY: -70,
        dataLabels: {
          offset: -10
        },
      }
    },
    dataLabels: {
      style: {
        fontSize: '18px',
        fontFamily: 'Roboto, Arial, sans-serif',
        fontWeight: 'bold',
        colors: ['#ffffff']
      }
    },
    responsive: [
      {
        breakpoint: 1200,
        options: {
          plotOptions: {
            pie: {
              offsetX: 0,
            }
          },
          legend: {
            offsetX: 0,
          }
        }
      },
      {
        breakpoint: 1000,
        options: {
          chart: {
            height: 300,
            width: '100%',
          },
        }
      },
      {
        breakpoint: 800,
        options: {
          plotOptions: {
            pie: {
              customScale: 1,
              offsetX: 0,
              offsetY: 0,
            }
          },
          subtitle: {
            margin: 20,
            offsetX: 0,
            offsetY: 40,
          },
          title: {
            margin: 20,
            offsetX: 0,
            offsetY: 0,
          },
          chart: {
            height: 10000,
            width: "100%",
          },
          legend: {
            width: '100%',
            position: "bottom",
            fontSize: '14px',
            offsetX: -20,
            offsetY: 0,
          },
          dataLabels: {
            style: {
              fontSize: '14px',
            }
          }
        }
      },
    ],
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: 'dark',
      custom: ({ seriesIndex, w }): string => {
        return `<div class="flex items-center h-8 min-h-8 max-h-8 px-3">
              <div class="w-3 h-3 rounded-full" style="background-color: ${w.globals.colors[seriesIndex]};"></div>
              <div class="ml-2 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
              <div class="ml-2 text-md font-bold leading-none">${w.globals.series[seriesIndex].toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}</div>
            </div>`
      }
    }
  };
  empresa: Empresa[] = [];
  ticket_medio: TicketMedio[] = [];
  date = new Date();
  init_dash = false;
  chart_width;
  filtro_request: FiltroRequest = {
    idusuario: localStorage.getItem('id'),
    dataInicio: this.date.toISOString().substring(0, 10),
    dataFim: this.date.toISOString().substring(0, 10),
    pro: Boolean(JSON.parse(localStorage.getItem('uscsw'))['pro_ativo']??false)
  };
  filtro = {
    dataInicio: this.date,
    dataFim: this.date,
    mesAtual: new Date().getMonth(),
    semanaAtual: 0,
    dia: 0,
    selecionado: 'DIA'
  }

  cht_vendas_title = { ...this.apexChartOptions.title };
  cht_vendas_subtitle = { ... this.apexChartOptions.subtitle };
  cht_vendas_series = { ... this.apexChartOptions.series };
  cht_vendas_labels = { ... this.apexChartOptions.labels };
  cht_vendas_legend = { ... this.apexChartOptions.legend };
  cht_vendas_tooltip = { ... this.apexChartOptions.tooltip };
  cht_vendas_colors = { ... this.apexChartOptions.colors };

  cht_total_title = { ...this.apexChartOptions.title };
  cht_total_subtitle = { ... this.apexChartOptions.subtitle };
  cht_total_series = { ... this.apexChartOptions.series };
  cht_total_labels = { ... this.apexChartOptions.labels };
  cht_total_legend = { ... this.apexChartOptions.legend };
  cht_total_tooltip = { ... this.apexChartOptions.tooltip };

  cht_ticket_title = { ...this.apexChartOptions.title };
  cht_ticket_subtitle = { ... this.apexChartOptions.subtitle };
  cht_ticket_series = { ... this.apexChartOptions.series };
  cht_ticket_labels = { ... this.apexChartOptions.labels };
  cht_ticket_legend = { ... this.apexChartOptions.legend };
  cht_ticket_tooltip = { ... this.apexChartOptions.tooltip };
  cht_ticket_colors = { ... this.apexChartOptions.colors };
  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private _document: HTMLDocument,
  ) {
  }
  mesAtual= new Date().getMonth();

  ngOnInit() {
  }
  ionViewWillEnter() {
    this.getFormaPgto();
  }
  getFormaPgto(event?) {
    this.init_dash = false;
    this.cdr.detectChanges();
    this.filtro_request.idusuario = localStorage.getItem('id');
    this.filtro_request.dataFim = this.datePipe.transform(this.filtro.dataFim, "yyyy-MM-dd");
    this.filtro_request.dataInicio = this.datePipe.transform(this.filtro.dataInicio, "yyyy-MM-dd");
    this.api.post('i/formaPgto', this.filtro_request).then(
      async (response: any) => {
        if (response) {
          this.empresa = [];

          for (let a = 0; a < response.empresa.length; a++) {
            let element: any = response.empresa[a];
            element = JSON.parse(element);

            // element.valorTotal = element.valorTotal.map(e => Number(e));
            // element.numVendas = element.numVendas.map(e => Number(e));
            // element.ticketMedio = element.ticketMedio.map(e => Number(e));


            let numVendas_E_labels = [];
            for (let index = 0; index < element.numVendas.length; index++) {
              numVendas_E_labels.push({
                numVendas: element.numVendas[index],
                label: element.formaPgto[index],
              })
            }
            numVendas_E_labels = numVendas_E_labels.sort((a: any, b: any) => { return Number(b.numVendas) - Number(a.numVendas) })
            element.numVendas = numVendas_E_labels.map(e => Number(e.numVendas));
            element.vendasLabels = numVendas_E_labels.map(e => e.label);

            let valorTotal_E_labels = [];
            for (let index = 0; index < element.valorTotal.length; index++) {
              valorTotal_E_labels.push({
                valorTotal: element.valorTotal[index],
                label: element.formaPgto[index],
              })
            }
            valorTotal_E_labels = valorTotal_E_labels.sort((a: any, b: any) => { return Number(b.valorTotal) - Number(a.valorTotal) })
            element.valorTotal = valorTotal_E_labels.map(e => Number(e.valorTotal));
            element.totalLabels = valorTotal_E_labels.map(e => e.label);

            let ticketMedio_E_labels = [];
            for (let index = 0; index < element.ticketMedio.length; index++) {
              ticketMedio_E_labels.push({
                ticketMedio: element.ticketMedio[index],
                label: element.formaPgto[index],
              })
            }
            ticketMedio_E_labels = ticketMedio_E_labels.sort((a: any, b: any) => { return Number(b.ticketMedio) - Number(a.ticketMedio) })

            element.ticketMedio = ticketMedio_E_labels.map(e => Number(e.ticketMedio));
            element.ticketLabels = ticketMedio_E_labels.map(e => e.label);

            let subtitleTotal = { ... this.apexChartOptions.subtitle };
            subtitleTotal.text = 'Valor Total: ' + element.valorTotal.map(e => Number(e)).reduce((partialSum, a) => partialSum + a, 0)
              .toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });
            element.subtitle = subtitleTotal;

            let subtitleVendas = { ... this.apexChartOptions.subtitle };
            subtitleVendas.text = 'Número de Vendas: ' + element.numVendas.map(e => Number(e)).reduce((partialSum, a) => partialSum + a, 0);
            element.subtitle = subtitleVendas;

            let subtitleTicket = { ... this.apexChartOptions.subtitle };
            subtitleTicket.text = 'Ticket Médio: ' + element.ticketMedio.map(e => Number(e)).reduce((partialSum, a) => partialSum + a, 0)
              .toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });

            element.subtitleTotal = subtitleTotal;
            element.subtitleVendas = subtitleVendas;
            element.subtitleTicket = subtitleTicket;

            element.numVendas_E_labels = numVendas_E_labels;
            element.valorTotal_E_labels = valorTotal_E_labels;
            element.ticketMedio_E_labels = ticketMedio_E_labels;

            this.empresa.push(element)
          }
          // for (let a = 0; a < this.empresa.length; a++) {
          //   const element = this.empresa[a];
          //   element.formaPgto = element.formaPgto.sort
          // }

          // ================valor total==============
          const sumValorTotal = response.formaPgto.map(e => Number(e.valorTotal)).reduce((partialSum, a) => partialSum + a, 0);
          let cht_total = response.formaPgto.sort((a: any, b: any) => { return Number(b.valorTotal) - Number(a.valorTotal) });
          this.cht_total_series = cht_total.map(e => Number(e.valorTotal));
          this.cht_total_labels = cht_total.map(e => e.formaPgto);
          this.cht_total_title.text = 'Valor Total';
          this.cht_total_subtitle.text = 'Valor Total = ' + sumValorTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });
          console.log('cht_total_subtitle', this.cht_total_subtitle)
          // ================vendas==============
          const sumNumeroVendas = response.totalNumVendas.map(e => Number(e.numVendas)).reduce((partialSum, a) => partialSum + a, 0);
          let cht_vendas = response.totalNumVendas.sort((a: any, b: any) => { return Number(b.numVendas) - Number(a.numVendas) });
          this.cht_vendas_series = cht_vendas.map(e => Number(e.numVendas));
          this.cht_vendas_labels = cht_vendas.map(e => e.formaPgto);
          this.cht_vendas_title.text = 'Número de Vendas';
          this.cht_vendas_subtitle.text = 'Total Número de Vendas = ' + sumNumeroVendas;
          this.cht_vendas_colors = ["#ffbc58", "#01cccd", "#6610f2", "#dc3545", "#f11fea", "#187e0c", "#fee440", "#00bbf9", "#00f5d4", "#f15bb5", "#15616d", "#78290f", "#ff99c8", "#92e6a7"];
          this.cht_vendas_tooltip.custom = ({ seriesIndex, w }): string => {
            return `<div class="flex items-center h-8 min-h-8 max-h-8 px-3">
                                <div class="w-3 h-3 rounded-full" style="background-color: ${w.globals.colors[seriesIndex]};"></div>
                                <div class="ml-2 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
                                <div class="ml-2 text-md font-bold leading-none">${w.globals.series[seriesIndex]}</div>
                              </div>`
          };
          this.cht_vendas_legend.formatter = (seriesName, opts) => {
            const round = (num, places) => {
              return +(parseFloat(num).toFixed(places));
            }
            // const class3w = (index) => {
            //   if (index % 2) {
            //     return '<span style="background-color:#dedede; padding: 10px 0; border-radius:2px; line-height: 2">';
            //   } else {
            //     return '<span >';
            //   }
            // }
            return `<span>${opts.w.globals.series[opts.seriesIndex] + " | " + round(opts.w.globals.seriesPercent[opts.seriesIndex], 2) + '%' + " | " + seriesName}
                        </span>`
          };
          // ================ticket==============
          this.ticket_medio = [];
          for (let a = 0; a < response.ticketMedio.length; a++) {
            const element = response.ticketMedio[a];
            this.ticket_medio.push(JSON.parse(element))
          }
          const sumTicketMedio = this.ticket_medio.map(e => Number(e.ticketMedio)).reduce((partialSum, a) => partialSum + a, 0);
          let cht_ticket = this.ticket_medio.sort((a: any, b: any) => { return Number(b.ticketMedio) - Number(a.ticketMedio) });
          this.cht_ticket_series = await cht_ticket.map((obj) => {
            return Number(obj.ticketMedio);
          });
          this.cht_ticket_labels = await cht_ticket.map((obj) => {
            return obj.formaPgto;
          });
          this.cht_ticket_title.text = 'Ticket Médio';
          this.cht_ticket_subtitle.text = 'Total Ticket Médio = ' + sumTicketMedio.toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });
          this.cht_ticket_colors = ["#e60049", "#0bb4ff", "#50e991", "#9b19f5", "#b3d4ff", "#ffa300", "#dc0ab4", "#00bfa0", "#e6d800", "#b30000", "#7c1158", "#4421af", "#1a53ff"];

        }
      }
    ).finally(() => {
      setTimeout(() => {
        this.init_dash = true;
        this.cdr.detectChanges();
        event?.target.complete();

      }, 200);
    });
  }

  proximo() {
    switch (this.filtro.selecionado) {
      case 'SEMANA':
        this.filtro.semanaAtual++;
        this.addSemana();
        break;
      case 'MES':
        this.filtro.mesAtual++;
        this.addMes();
        break;
      case 'DIA':
        this.filtro.dia++;
        this.addDia();
        break;
    }
  }

  anterior() {
    switch (this.filtro.selecionado) {
      case 'SEMANA':
        this.filtro.semanaAtual--;
        this.addSemana();
        break;
      case 'MES':
        this.filtro.mesAtual--;
        this.addMes();
        break;
      case 'DIA':
        this.filtro.dia--;
        this.addDia();
        break;
    }
  }

  addSemana() {
    this.filtro.selecionado = 'SEMANA';
    let inicio = moment().add(this.filtro.semanaAtual, 'weeks').day(0); // domingo desta semana
    let fim = moment().add(this.filtro.semanaAtual, 'weeks').day(6); // sábado desta semana
    this.filtro.dataInicio = new Date(inicio.toString());
    this.filtro.dataFim = new Date(fim.toString());
  }
  addMes() {
    this.filtro.selecionado = 'MES';
    let d = new Date();
    let anoC = d.getFullYear();
    let mesC = this.filtro.mesAtual;
    this.filtro.dataInicio = new Date(anoC, mesC, 1);
    this.filtro.dataFim = new Date(anoC, mesC + 1, 0);
  }
  addDia() {
    this.filtro.selecionado = 'DIA';
    let date = new Date();
    let emissaoDe = new Date();
    let emissaoAte = new Date();
    this.filtro.dataInicio = new Date(emissaoDe.setDate(date.getDate() + this.filtro.dia));
    this.filtro.dataFim = new Date(emissaoAte.setDate(date.getDate() + this.filtro.dia));
  }
}
