import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexChart,
  ApexNonAxisChartSeries,
  ApexOptions,
  ApexResponsive,
  ChartComponent,
} from 'ng-apexcharts';
import { ApiService } from '../service/api.service';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { ViewWillEnter } from '@ionic/angular';
import { MatDialog } from '@angular/material/dialog';
import { EmailUsuarioDialogComponent } from '../components/email-usuario-dialog/email-usuario-dialog.component';
import { Overlay } from '@angular/cdk/overlay';
import { SuccessDialogComponent } from '../components/success-dialog/success-dialog.component';
import { Debounce } from '../decorators/debouce-decorator';
import { groupBy } from 'lodash';
interface Empresa {
  empresa: string;
  valorTotal: string;
  numVendas: string;
  ticketMedio: string;
}

interface FiltroRequest {
  idusuario: string;
  dataInicio: string;
  dataFim: string;
  dataInicio_anterior: string;
  dataFim_anterior: string;
}
@Component({
  selector: 'app-geral',
  templateUrl: './geral.component.html',
  styleUrls: ['./geral.component.scss'],
})
export class GeralComponent implements OnInit, ViewWillEnter {
  apexChartOptions: ApexOptions = {
    chart: {
      height: 500,
      width: '100%',
      type: 'pie',
      animations: {
        speed: 400,
      },
    },
    labels: [],
    series: [],
    legend: {
      // position: 'bottom',
      width: 400,
      horizontalAlign: 'left',
      fontSize: '14px',
      offsetX: 0,
      itemMargin: {
        horizontal: 0,
        vertical: 0,
      },
      formatter: (seriesName, opts) => {
        const round = (num, places) => {
          return +parseFloat(num).toFixed(places);
        };
        // const class3w = (index) => {
        //   if (index % 2) {
        //     return '<span style="background-color:#dedede; padding: 10px 0; border-radius:2px; line-height: 2.5">';
        //   } else {
        //     return '<span >';
        //   }
        // }
        // return class3w(opts.seriesIndex) + `

        return `<span >${
          opts.w.globals.series[opts.seriesIndex].toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
          }) +
          ' | ' +
          round(opts.w.globals.seriesPercent[opts.seriesIndex], 2) +
          '%' +
          ' | ' +
          seriesName
        } </span>`;
      },
    },
    title: {
      text: undefined,
      align: 'left',
      margin: 20,
      offsetX: 50,
      offsetY: 0,
      style: {
        fontSize: '25px',
        fontWeight: 'bold',
        fontFamily: 'inherit',
        color: '#303030',
      },
    },
    subtitle: {
      text: undefined,
      align: 'left',
      margin: 20,
      offsetX: 50,
      offsetY: 370,
      style: {
        fontSize: '18px',
        fontWeight: 'normal',
        fontFamily: 'inherit',
        color: '#303030',
      },
    },
    plotOptions: {
      pie: {
        customScale: 0.8,
        expandOnClick: true,
        offsetX: -100,
        offsetY: -70,
        dataLabels: {
          offset: -10,
        },
      },
    },
    dataLabels: {
      style: {
        fontSize: '18px',
        fontFamily: 'Roboto, Arial, sans-serif',
        fontWeight: 'bold',
        colors: ['#ffffff'],
      },
    },
    responsive: [
      {
        breakpoint: 1200,
        options: {
          plotOptions: {
            pie: {
              offsetX: 0,
            },
          },
          legend: {
            offsetX: 0,
          },
        },
      },
      {
        breakpoint: 1000,
        options: {
          chart: {
            height: 300,
            width: '100%',
          },
        },
      },
      {
        breakpoint: 800,
        options: {
          plotOptions: {
            pie: {
              customScale: 1,
              offsetX: 0,
              offsetY: 0,
            },
          },
          subtitle: {
            margin: 20,
            offsetX: 0,
            offsetY: 40,
          },
          title: {
            margin: 20,
            offsetX: 0,
            offsetY: 0,
          },

          chart: {
            height: 10000,
            width: '100%',
          },
          legend: {
            width: '100%',
            position: 'bottom',
            fontSize: '14px',
            offsetX: -20,
            offsetY: 0,
          },
          dataLabels: {
            style: {
              fontSize: '14px',
            },
          },
        },
      },
    ],
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: 'dark',
      custom: ({ seriesIndex, w }): string => {
        return `<div class="flex items-center h-8 min-h-8 max-h-8 px-3">
              <div class="w-3 h-3 rounded-full" style="background-color: ${
                w.globals.colors[seriesIndex]
              };"></div>
              <div class="ml-2 text-md leading-none">${
                w.config.labels[seriesIndex]
              }:</div>
              <div class="ml-2 text-md font-bold leading-none">${w.globals.series[
                seriesIndex
              ].toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'BRL',
              })}</div>
            </div>`;
      },
    },
  };
  empresa: Empresa[] = [];
  date = new Date();
  init_dash = false;

  cht_vendas_title = { ...this.apexChartOptions.title };
  cht_vendas_subtitle = { ...this.apexChartOptions.subtitle };
  cht_vendas_series = { ...this.apexChartOptions.series };
  cht_vendas_labels = { ...this.apexChartOptions.labels };
  cht_vendas_legend = { ...this.apexChartOptions.legend };
  cht_vendas_tooltip = { ...this.apexChartOptions.tooltip };
  cht_vendas_colors = { ...this.apexChartOptions.colors };

  cht_total_title = { ...this.apexChartOptions.title };
  cht_total_subtitle = { ...this.apexChartOptions.subtitle };
  cht_total_series = { ...this.apexChartOptions.series };
  cht_total_labels = { ...this.apexChartOptions.labels };
  cht_total_legend = { ...this.apexChartOptions.legend };
  cht_total_tooltip = { ...this.apexChartOptions.tooltip };

  cht_ticket_title = { ...this.apexChartOptions.title };
  cht_ticket_subtitle = { ...this.apexChartOptions.subtitle };
  cht_ticket_series = { ...this.apexChartOptions.series };
  cht_ticket_labels = { ...this.apexChartOptions.labels };
  cht_ticket_legend = { ...this.apexChartOptions.legend };
  cht_ticket_tooltip = { ...this.apexChartOptions.tooltip };
  cht_ticket_colors = { ...this.apexChartOptions.colors };

  filtro_request: FiltroRequest = {
    idusuario: localStorage.getItem('id'),
    dataInicio: this.date.toISOString().substring(0, 10),
    dataFim: this.date.toISOString().substring(0, 10),
    dataInicio_anterior: this.date.toISOString().substring(0, 10),
    dataFim_anterior: this.date.toISOString().substring(0, 10),
  };
  filtro = {
    dataInicio: this.date,
    dataFim: this.date,
    dataInicio_anterior: this.date,
    dataFim_anterior: this.date,
    mesAtual: 0,
    semanaAtual: 0,
    dia: 0,
    selecionado: 'DIA',
  };
  header_dados = {
    valor_total: {
      anterior: 0,
      atual: 0,
    },
    ticketMedio: {
      anterior: 0,
      atual: 0,
    },
    numVendas: {
      anterior: 2,
      atual: 0,
    },
  };
  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private overlay: Overlay
  ) {
    console.log(' constructor')
  }

  ngOnInit() {
    console.log(' ngOnInit')

    this.startApp();
  }
  ionViewWillEnter() {
    console.log(' ionViewWillEnter')

    this.startApp();
  }
  @Debounce(250)
  startApp(){
    const usuario = JSON.parse(localStorage.getItem('uscsw'));
    this.usuario = usuario;
    if (this.usuario.pro_ativo) {
      this.addMes();
    }
    this.verificarEmailUsuario();
    this.getContadorVendaItem();
  }

  getContador(event?) {
    this.init_dash = false;
    this.cdr.detectChanges();
    this.cdr.markForCheck();
    this.filtro_request.idusuario = localStorage.getItem('id')
      ? localStorage.getItem('id')
      : 'null';
    this.filtro_request.dataFim = this.datePipe.transform(
      this.filtro.dataFim,
      'yyyy-MM-dd'
    );
    this.filtro_request.dataInicio = this.datePipe.transform(
      this.filtro.dataInicio,
      'yyyy-MM-dd'
    );
    this.api
      .post('i/contador', this.filtro_request)
      .then(async (response: any) => {
        if (response) {
          this.empresa = [];
          for (let a = 0; a < response.empresa.length; a++) {
            const element = response.empresa[a];
            this.empresa.push(JSON.parse(element));

            // ==============labels=============
          }
          // this.cht_labels = await this.empresa.map((obj) => {
          //   return obj.empresa;
          // });

          // ================valor total==============
          this.cht_total_title.text = 'Valor Total';
          this.cht_total_subtitle.text =
            'Valor Total = ' +
            Number(response.valorTotal).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            });
          let cht_total = this.empresa.sort((a: any, b: any) => {
            return Number(b.valorTotal) - Number(a.valorTotal);
          });
          this.cht_total_series = await cht_total.map((obj) => {
            return Number(obj.valorTotal);
          });
          this.cht_total_labels = await cht_total.map((obj) => {
            return obj.empresa;
          });

          // ================vendas==============
          this.cht_vendas_title.text = 'Número de vendas';
          this.cht_vendas_subtitle.text =
            'Total Números de Vendas = ' + response.numVendas[0].numVendas;
          let cht_vendas = this.empresa.sort((a: any, b: any) => {
            return Number(b.numVendas) - Number(a.numVendas);
          });
          this.cht_vendas_series = await cht_vendas.map((obj) => {
            return Number(obj.numVendas);
          });
          this.cht_vendas_labels = await cht_vendas.map((obj) => {
            return obj.empresa;
          });

          this.cht_vendas_tooltip.custom = ({ seriesIndex, w }): string => {
            return `<div class="flex items-center h-8 min-h-8 max-h-8 px-3">
                      <div class="w-3 h-3 rounded-full" style="background-color: ${w.globals.colors[seriesIndex]};"></div>
                      <div class="ml-2 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
                      <div class="ml-2 text-md font-bold leading-none">${w.globals.series[seriesIndex]}</div>
                    </div>`;
          };
          this.cht_vendas_legend.formatter = (seriesName, opts) => {
            const round = (num, places) => {
              return +parseFloat(num).toFixed(places);
            };
            // const class3w = (index) => {
            //   // if (index % 2) {
            //   //   return '<span style="background-color:#dedede; padding: 10px 0; border-radius:2px; line-height: 2">';
            //   // } else {
            //   //   return '<span >';
            //   // }
            // }
            return `<span >
              ${
                opts.w.globals.series[opts.seriesIndex] +
                ' | ' +
                round(opts.w.globals.seriesPercent[opts.seriesIndex], 2) +
                '%' +
                ' | ' +
                seriesName
              }
              </span>`;
          };
          this.cht_vendas_colors = [
            '#ffbc58',
            '#01cccd',
            '#6610f2',
            '#dc3545',
            '#f11fea',
            '#187e0c',
            '#fee440',
            '#00bbf9',
            '#00f5d4',
            '#f15bb5',
            '#15616d',
            '#78290f',
            '#ff99c8',
            '#92e6a7',
          ];

          // ================ticket==============
          this.cht_ticket_title.text = 'Ticket Médio';
          this.cht_ticket_subtitle.text =
            'Total Ticket Médio = ' +
            Number(response.ticketMedio).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            });
          let cht_ticket = this.empresa.sort((a: any, b: any) => {
            return Number(b.ticketMedio) - Number(a.ticketMedio);
          });
          this.cht_ticket_series = await cht_ticket.map((obj) => {
            return Number(obj.ticketMedio);
          });
          this.cht_ticket_labels = await cht_ticket.map((obj) => {
            return obj.empresa;
          });
          this.cht_ticket_colors = [
            '#e60049',
            '#0bb4ff',
            '#50e991',
            '#9b19f5',
            '#b3d4ff',
            '#ffa300',
            '#dc0ab4',
            '#00bfa0',
            '#e6d800',
            '#b30000',
            '#7c1158',
            '#4421af',
            '#1a53ff',
          ];

          let filtro_anterior = { ...this.filtro_request };
          filtro_anterior.dataFim = this.datePipe.transform(
            this.filtro.dataFim_anterior,
            'yyyy-MM-dd'
          );
          filtro_anterior.dataInicio = this.datePipe.transform(
            this.filtro.dataInicio_anterior,
            'yyyy-MM-dd'
          );
          await this.api
            .post('i/contador', filtro_anterior)
            .then((DADOS_ANTERIORES) => {
              this.header_dados = {
                valor_total: {
                  anterior: Number(DADOS_ANTERIORES.valorTotal),
                  atual: Number(response.valorTotal),
                },
                ticketMedio: {
                  anterior: Number(DADOS_ANTERIORES.ticketMedio),
                  atual: Number(response.ticketMedio),
                },
                numVendas: {
                  anterior: Number(DADOS_ANTERIORES.numVendas[0].numVendas),
                  atual: Number(response.numVendas[0].numVendas),
                },
              };
            });
        }
      })
      .finally(() => {
        setTimeout(() => {
          this.init_dash = true;
          this.cdr.detectChanges();
          this.cdr.markForCheck();
          event?.target.complete();
        }, 200);
      });
  }
  async getContadorVendaItem(event?) {

    this.init_dash = false;
    this.cdr.detectChanges();
    this.cdr.markForCheck();
    if (this.usuario.pro_ativo) {
      await this.getEmpresas(event);

    } else {
      await this.getContador(event);

    }
    // console.log('init_dash', this.init_dash)
    // this.filtro_request.idusuario = localStorage.getItem('id') ? localStorage.getItem('id') : 'null';
    // this.filtro_request.dataFim = this.datePipe.transform(this.filtro.dataFim, "yyyy-MM-dd");
    // this.filtro_request.dataInicio = this.datePipe.transform(this.filtro.dataInicio, "yyyy-MM-dd");
    // setTimeout(() => {
    //   this.init_dash = true;
    //   console.log('init_dash', this.init_dash)
    //   this.cdr.detectChanges();
    //   this.cdr.markForCheck();
    //   event?.target.complete();
    // }, 200);
  }
  empresas = [];
  @Debounce(250)
  async getEmpresas(event?) {
    this.empresas = [];
    this.init_dash = false;

    try {
      const response: any = await this.api.post('pesquisarUsuarioEmpresa', {
        idusuario: localStorage.getItem('id'),
      })

      if (response){
        // Parse das empresas
        let empresasParsed = response.empresas.map((e) => (e = JSON.parse(e)));
        this.empresas = empresasParsed;

        // Executa as chamadas para cada empresa em paralelo e aguarda todas concluírem
        const dashPromises = this.empresas.map(empresa => this.dashValorTotal(empresa));
        const dashResults = await Promise.all(dashPromises);

        // Atualiza cada empresa com os dados calculados
        this.empresas = this.empresas.map((empresa, index)=>({
          ...empresa,
          ...dashResults[index]
        }));

        // Cálculo dos totais para os gráficos
        this.header_dados = {
          valor_total: {
            anterior: this.empresas
              .map(e => e.valor_total.anterior)
              .reduce((a, b) => a + b, 0),
            atual: this.empresas
              .map(e => e.valor_total.atual)
              .reduce((a, b) => a + b, 0),
          },
          ticketMedio: {
            anterior: this.empresas
              .map(e => e.ticket_medio.anterior)
              .reduce((a, b) => a + b, 0),
            atual: this.empresas
              .map(e => e.ticket_medio.atual)
              .reduce((a, b) => a + b, 0),
          },
          numVendas: {
            anterior: this.empresas
              .map(e => e.total_vendas.anterior)
              .reduce((a, b) => a + b, 0),
            atual: this.empresas
              .map(e => e.total_vendas.atual)
              .reduce((a, b) => a + b, 0),
          },
        };

        // Configuração dos gráficos (exemplo para Valor Total)
        this.cht_total_title.text = 'Valor Total';
        this.cht_total_subtitle.text =
          'Valor Total = ' +
          Number(this.header_dados.valor_total.atual).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
          });

        const cht_total = this.empresas
          .slice() // cria uma cópia para não alterar a ordem original
          .sort((a: any, b: any) => Number(b.valor_total.atual) - Number(a.valor_total.atual));

        this.cht_total_series = cht_total.map(obj => Number(obj.valor_total.atual));
        this.cht_total_labels = cht_total.map(obj => obj.descricao);

        // Configuração dos gráficos para vendas
        this.cht_vendas_title.text = 'Número de vendas';
        this.cht_vendas_subtitle.text =
          'Total Número de Vendas = ' + this.header_dados.numVendas.atual;
        const cht_vendas = this.empresas.slice().sort((a: any, b: any) => Number(b.total_vendas.atual) - Number(a.total_vendas.atual));
        this.cht_vendas_series = cht_vendas.map(obj => Number(obj.total_vendas.atual));
        this.cht_vendas_labels = cht_vendas.map(obj => obj.descricao);
        this.cht_vendas_tooltip.custom = ({ seriesIndex, w }): string => {
          return `<div class="flex items-center h-8 min-h-8 max-h-8 px-3">
                    <div class="w-3 h-3 rounded-full" style="background-color: ${w.globals.colors[seriesIndex]};"></div>
                    <div class="ml-2 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
                    <div class="ml-2 text-md font-bold leading-none">${w.globals.series[seriesIndex]}</div>
                  </div>`;
        };
        this.cht_vendas_legend.formatter = (seriesName, opts) => {
          const round = (num, places) => +parseFloat(num).toFixed(places);
          return `<span>
            ${opts.w.globals.series[opts.seriesIndex] + ' | ' +
              round(opts.w.globals.seriesPercent[opts.seriesIndex], 2) +
              '%' + ' | ' + seriesName}
            </span>`;
        };
        this.cht_vendas_colors = [
          '#ffbc58',
          '#01cccd',
          '#6610f2',
          '#dc3545',
          '#f11fea',
          '#187e0c',
          '#fee440',
          '#00bbf9',
          '#00f5d4',
          '#f15bb5',
          '#15616d',
          '#78290f',
          '#ff99c8',
          '#92e6a7',
        ];

        // Configuração dos gráficos para Ticket Médio
        this.cht_ticket_title.text = 'Ticket Médio';
        this.cht_ticket_subtitle.text =
          'Total Ticket Médio = ' +
          Number(this.header_dados.ticketMedio.atual).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
          });
        const cht_ticket = this.empresas.slice().sort((a: any, b: any) => Number(b.ticket_medio.atual) - Number(a.ticket_medio.atual));
        this.cht_ticket_series = cht_ticket.map(obj => Number(obj.ticket_medio.atual));
        this.cht_ticket_labels = cht_ticket.map(obj => obj.descricao);
        this.cht_ticket_colors = [
          '#e60049',
          '#0bb4ff',
          '#50e991',
          '#9b19f5',
          '#b3d4ff',
          '#ffa300',
          '#dc0ab4',
          '#00bfa0',
          '#e6d800',
          '#b30000',
          '#7c1158',
          '#4421af',
          '#1a53ff',
        ];
      }
    }
    catch (error) {
      console.error('Erro ao carregar empresas', error);
    } finally{
      setTimeout(() => {
        this.init_dash = true;
        this.cdr.detectChanges();
        this.cdr.markForCheck();
        event?.target.complete();
      }, 200);
    }
    // await this.api
    //   .post('pesquisarUsuarioEmpresa', {
    //     idusuario: localStorage.getItem('id'),
    //   })
    //   .then(async (response: any) => {
    //     if (response) {
    //       let empresas = response.empresas.map((e) => (e = JSON.parse(e)));
    //       this.empresas = empresas;

    //       for (let index = 0; index < this.empresas.length; index++) {
    //         const empresa = this.empresas[index];
    //         await this.dashValorTotal(empresa, index);
    //       }
    //       this.header_dados = {
    //         valor_total: {
    //           anterior: this.empresas
    //             .map((e) => e.valor_total.anterior)
    //             .reduce((a, b) => a + b, 0),
    //           atual: this.empresas
    //             .map((e) => e.valor_total.atual)
    //             .reduce((a, b) => a + b, 0),
    //         },
    //         ticketMedio: {
    //           anterior: this.empresas
    //             .map((e) => e.ticket_medio.anterior)
    //             .reduce((a, b) => a + b, 0),
    //           atual: this.empresas
    //             .map((e) => e.ticket_medio.atual)
    //             .reduce((a, b) => a + b, 0),
    //         },
    //         numVendas: {
    //           anterior: this.empresas
    //             .map((e) => e.total_vendas.anterior)
    //             .reduce((a, b) => a + b, 0),
    //           atual: this.empresas
    //             .map((e) => e.total_vendas.atual)
    //             .reduce((a, b) => a + b, 0),
    //         },
    //       };
    //       this.cht_total_title.text = 'Valor Total';
    //       this.cht_total_subtitle.text =
    //         'Valor Total = ' +
    //         Number(this.header_dados.valor_total.atual).toLocaleString('pt-BR', {
    //           minimumFractionDigits: 2,
    //           style: 'currency',
    //           currency: 'BRL',
    //         });
    //       let cht_total = this.empresas.sort((a: any, b: any) => {
    //         return Number(b.valor_total.atual) - Number(a.valor_total.atual);
    //       });
    //       this.cht_total_series = await cht_total.map((obj) => {
    //         return Number(obj.valor_total.atual);
    //       });

    //       this.cht_total_labels = await cht_total.map((obj) => {
    //         return obj.descricao;
    //       });

    //       // ================vendas==============
    //       this.cht_vendas_title.text = 'Número de vendas';
    //       this.cht_vendas_subtitle.text =
    //         'Total Números de Vendas = ' + this.header_dados.numVendas.atual;
    //       let cht_vendas = this.empresas.sort((a: any, b: any) => {
    //         return Number(b.total_vendas.atual) - Number(a.total_vendas.atual);
    //       });
    //       this.cht_vendas_series = await cht_vendas.map((obj) => {
    //         return Number(obj.total_vendas.atual);
    //       });

    //       this.cht_vendas_labels = await cht_vendas.map((obj) => {
    //         return obj.descricao;
    //       });

    //       this.cht_vendas_tooltip.custom = ({ seriesIndex, w }): string => {
    //         return `<div class="flex items-center h-8 min-h-8 max-h-8 px-3">
    //                   <div class="w-3 h-3 rounded-full" style="background-color: ${w.globals.colors[seriesIndex]};"></div>
    //                   <div class="ml-2 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
    //                   <div class="ml-2 text-md font-bold leading-none">${w.globals.series[seriesIndex]}</div>
    //                 </div>`;
    //       };
    //       this.cht_vendas_legend.formatter = (seriesName, opts) => {
    //         const round = (num, places) => {
    //           return +parseFloat(num).toFixed(places);
    //         };
    //         // const class3w = (index) => {
    //         //   // if (index % 2) {
    //         //   //   return '<span style="background-color:#dedede; padding: 10px 0; border-radius:2px; line-height: 2">';
    //         //   // } else {
    //         //   //   return '<span >';
    //         //   // }
    //         // }
    //         return `<span >
    //           ${
    //             opts.w.globals.series[opts.seriesIndex] +
    //             ' | ' +
    //             round(opts.w.globals.seriesPercent[opts.seriesIndex], 2) +
    //             '%' +
    //             ' | ' +
    //             seriesName
    //           }
    //           </span>`;
    //       };
    //       this.cht_vendas_colors = [
    //         '#ffbc58',
    //         '#01cccd',
    //         '#6610f2',
    //         '#dc3545',
    //         '#f11fea',
    //         '#187e0c',
    //         '#fee440',
    //         '#00bbf9',
    //         '#00f5d4',
    //         '#f15bb5',
    //         '#15616d',
    //         '#78290f',
    //         '#ff99c8',
    //         '#92e6a7',
    //       ];

    //       // ================ticket==============
    //       this.cht_ticket_title.text = 'Ticket Médio';
    //       this.cht_ticket_subtitle.text =
    //         'Total Ticket Médio = ' +
    //         Number(this.header_dados.ticketMedio.atual).toLocaleString('pt-BR', {
    //           minimumFractionDigits: 2,
    //           style: 'currency',
    //           currency: 'BRL',
    //         });
    //       let cht_ticket = this.empresas.sort((a: any, b: any) => {
    //         return Number(b.ticket_medio.atual) - Number(a.ticket_medio.atual);
    //       });
    //       this.cht_ticket_series = await cht_ticket.map((obj) => {
    //         return Number(obj.ticket_medio.atual);
    //       });
    //       this.cht_ticket_labels = await cht_ticket.map((obj) => {
    //         return obj.descricao;
    //       });
    //       this.cht_ticket_colors = [
    //         '#e60049',
    //         '#0bb4ff',
    //         '#50e991',
    //         '#9b19f5',
    //         '#b3d4ff',
    //         '#ffa300',
    //         '#dc0ab4',
    //         '#00bfa0',
    //         '#e6d800',
    //         '#b30000',
    //         '#7c1158',
    //         '#4421af',
    //         '#1a53ff',
    //       ];

    //       // console.log(this.header_dados);

    //       // this.empresas.unshift( {descricao: "Todas Empresas", idempresa: this.empresas.map(e => e.idempresa).join(',')})
    //     }
    //   }).finally(() => {
    //     setTimeout(() => {
    //       this.init_dash = true;
    //       this.cdr.detectChanges();
    //       this.cdr.markForCheck();
    //       event?.target.complete();
    //     }, 200);
    //   });;
  }

  async dashValorTotal(empresa): Promise<any> {
    try {
      const [produtos_atual, produtos_anterior] = await Promise.all([
        this.api.get('venda-item/listar', {
          empresa_csw: empresa?.idempresa,
          data_inicio: this.datePipe.transform(this.filtro.dataInicio, 'yyyy-MM-dd'),
          data_fim: this.datePipe.transform(this.filtro.dataFim, 'yyyy-MM-dd'),
        }),
        this.api.get('venda-item/listar', {
          empresa_csw: empresa?.idempresa,
          data_inicio: this.datePipe.transform(this.filtro.dataInicio_anterior, 'yyyy-MM-dd'),
          data_fim: this.datePipe.transform(this.filtro.dataFim_anterior, 'yyyy-MM-dd'),
        })
      ]);

      const vendas_atual = groupBy(produtos_atual, 'venda_id');
      const vendas_anterior = groupBy(produtos_anterior, 'venda_id');

      const totalValorAtual = produtos_atual
        .map(e => Number(e.valor_total))
        .reduce((a, b) => a + b, 0);
      const totalValorAnterior = produtos_anterior
        .map(e => Number(e.valor_total))
        .reduce((a, b) => a + b, 0);

      const totalVendasAtual = Object.keys(vendas_atual).length;
      const totalVendasAnterior = Object.keys(vendas_anterior).length;

      const ticketMedioAtual = totalVendasAtual === 0 ? 0 : totalValorAtual / totalVendasAtual;
      const ticketMedioAnterior = totalVendasAnterior === 0 ? 0 : totalValorAnterior / totalVendasAnterior;

      return {
        valor_total: {
          atual: totalValorAtual,
          anterior: totalValorAnterior,
        },
        total_vendas: {
          atual: totalVendasAtual,
          anterior: totalVendasAnterior,
        },
        ticket_medio: {
          atual: ticketMedioAtual,
          anterior: ticketMedioAnterior,
        },
      };
    }
    catch (error){
      console.error('Erro em dashValorTotal para empresa', empresa?.idempresa, error);
      return {
        valor_total: { atual: 0, anterior: 0 },
        total_vendas: { atual: 0, anterior: 0 },
        ticket_medio: { atual: 0, anterior: 0 },
      };
    }
  }
  // async dashValorTotal(empresa, index_empresa) {
  //   let produtos_atual = await this.api.get('venda-item/listar', {
  //     empresa_csw: empresa?.idempresa,
  //     data_inicio: this.datePipe.transform(
  //       this.filtro.dataInicio,
  //       'yyyy-MM-dd'
  //     ),
  //     data_fim: this.datePipe.transform(this.filtro.dataFim, 'yyyy-MM-dd'),
  //   });
  //   let produtos_anterior = await this.api.get('venda-item/listar', {
  //     empresa_csw: empresa?.idempresa,
  //     data_inicio: this.datePipe.transform(
  //       this.filtro.dataInicio_anterior,
  //       'yyyy-MM-dd'
  //     ),
  //     data_fim: this.datePipe.transform(
  //       this.filtro.dataFim_anterior,
  //       'yyyy-MM-dd'
  //     ),
  //   });

  //   let vendas_atual = groupBy(produtos_atual, 'venda_id');
  //   let vendas_anterior = groupBy(produtos_anterior, 'venda_id');

  //   let DATA = {
  //     valor_total: {
  //       atual: produtos_atual
  //         .map((e) => Number(e.valor_total))
  //         .reduce((a, b) => a + b, 0),
  //       anterior: produtos_anterior
  //         .map((e) => Number(e.valor_total))
  //         .reduce((a, b) => a + b, 0),
  //     },
  //     total_vendas: {
  //       atual: Object.keys(vendas_atual).length,
  //       anterior: Object.keys(vendas_anterior).length,
  //     },
  //     ticket_medio: {
  //       atual:
  //         Object.keys(vendas_atual).length == 0
  //           ? 0
  //           : produtos_atual
  //               .map((e) => Number(e.valor_total))
  //               .reduce((a, b) => a + b, 0) / Object.keys(vendas_atual).length,
  //       anterior:
  //         Object.keys(vendas_anterior).length == 0
  //           ? 0
  //           : produtos_anterior
  //               .map((e) => Number(e.valor_total))
  //               .reduce((a, b) => a + b, 0) /
  //             Object.keys(vendas_anterior).length,
  //     },
  //   };
  //   this.empresas[index_empresa] = { ...this.empresas[index_empresa], ...DATA };
  // }

  proximo() {
    switch (this.filtro.selecionado) {
      case 'SEMANA':
        this.filtro.semanaAtual++;
        this.addSemana();
        break;
      case 'MES':
        this.filtro.mesAtual++;
        this.addMes();
        break;
      case 'DIA':
        this.filtro.dia++;
        this.addDia();
        break;
    }
  }

  anterior() {
    switch (this.filtro.selecionado) {
      case 'SEMANA':
        this.filtro.semanaAtual--;
        this.addSemana();
        break;
      case 'MES':
        this.filtro.mesAtual--;
        this.addMes();
        break;
      case 'DIA':
        this.filtro.dia--;
        this.addDia();
        break;
    }
  }

  addSemana() {
    this.filtro.selecionado = 'SEMANA';
    let inicio = moment().add(this.filtro.semanaAtual, 'weeks').day(0); // domingo desta semana
    let fim = moment().add(this.filtro.semanaAtual, 'weeks').day(6); // sábado desta semana
    this.filtro.dataInicio = new Date(inicio.toString());
    this.filtro.dataFim = new Date(fim.toString());
    let inicio_anterior = moment()
      .add(this.filtro.semanaAtual - 1, 'weeks')
      .day(0); // domingo desta semana
    let fim_anterior = moment()
      .add(this.filtro.semanaAtual - 1, 'weeks')
      .day(6); // sábado desta semana
    this.filtro.dataInicio_anterior = new Date(inicio_anterior.toString());
    this.filtro.dataFim_anterior = new Date(fim_anterior.toString());
  }
  addMes() {
    this.filtro.selecionado = 'MES';
    let inicio = moment().add(this.filtro.mesAtual, 'month').startOf('month'); // domingo desta semana
    let fim = moment().add(this.filtro.mesAtual, 'month').endOf('month'); // sábado desta semana
    this.filtro.dataInicio = new Date(inicio.toString());
    this.filtro.dataFim = new Date(fim.toString());
    let inicio_anterior = moment()
      .add(this.filtro.mesAtual - 1, 'month')
      .startOf('month'); // domingo desta semana
    let fim_anterior = moment()
      .add(this.filtro.mesAtual - 1, 'month')
      .endOf('month'); // sábado desta semana
    this.filtro.dataInicio_anterior = new Date(inicio_anterior.toString());
    this.filtro.dataFim_anterior = new Date(fim_anterior.toString());
  }
  addDia() {
    this.filtro.selecionado = 'DIA';
    let inicio = moment().add(this.filtro.dia, 'day').startOf('day'); // domingo desta semana
    let fim = moment().add(this.filtro.dia, 'day').endOf('day'); // sábado desta semana
    this.filtro.dataInicio = new Date(inicio.toString());
    this.filtro.dataFim = new Date(fim.toString());
    let inicio_anterior = moment()
      .add(this.filtro.dia - 1, 'day')
      .startOf('day'); // domingo desta semana
    let fim_anterior = moment()
      .add(this.filtro.dia - 1, 'day')
      .endOf('day'); // sábado desta semana
    this.filtro.dataInicio_anterior = new Date(inicio_anterior.toString());
    this.filtro.dataFim_anterior = new Date(fim_anterior.toString());
  }

  verificarEmailUsuario() {
    if (localStorage.getItem('uscsw')) {
      const usuario = JSON.parse(localStorage.getItem('uscsw'));
      this.usuario = usuario;
      if (
        !usuario.email ||
        usuario.email == '' ||
        usuario.email == null ||
        usuario.email == 'null' ||
        usuario.email == undefined ||
        usuario.email == 'undefined'
      ) {
        this.getUsuario();
      }
    } else {
      this.getUsuario();
    }
  }
  usuario;
  getUsuario() {
    this.api.get('usuario/' + localStorage.getItem('id')).then((e: any) => {
      localStorage.setItem('uscsw', JSON.stringify(e));
      this.usuario = e;
      if (!e.email) {
        this.openDialogExcluirUsuario(e);
      }
    });
  }
  openDialogExcluirUsuario(usuario): void {
    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const { ...data } = {
      // icon: 'help_outline',
      icon: 'warning',
      icon_color: '#a70808',
      title: '<span class="text-red-800">Atenção</span>',
      sub_title: 'Você deve adicionar um email válido ao seu usuário.',
      message:
        'Com o esse email você poderá resetar sua senha com mais segurança. <br><br> Esse email não poderá ser usado em outro usuário do IndicaBR. <br><br> Todos usuários devem adicionar um email a partir de 20/06/2022.',
      actions: {
        confirm: {
          label: 'ADICIONAR EMAIL',
          color: '#a70808',
        },
        // "cancel": {
        //   "label": "Cancelar"
        // }
      },
      dismissible: true,
    };
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      width: '500px',
      data: data,
      panelClass: 'succes-modal',
      scrollStrategy,
    });
    dialogRef.afterClosed().subscribe((result) => {
      const dialogRef = this.dialog.open(EmailUsuarioDialogComponent, {
        width: '500px',
        data: usuario,
        disableClose: true,
        panelClass: 'succes-modal',
        scrollStrategy,
      });
    });
  }
  scroll(element: HTMLElement) {
    const yOffset = -10;
    // const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  }
}
