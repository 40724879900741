
import { Directory, Filesystem, FilesystemDirectory } from '@capacitor/filesystem';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { ApiService } from 'src/app/service/api.service';
import * as XLSX from 'xlsx';
import write_blob from 'capacitor-blob-writer';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Platform } from '@ionic/angular';
import { convertBlobToBase64, isWindows, snakeCase } from 'src/app/utils';
import { Share } from '@capacitor/share';
interface Empresa {
  empresa: string;
  valorTotal: string;
  numVendas: string;
  ticketMedio: string;
}

interface FiltroRequest {
  data_inicio: string;
  data_fim: string;
  qtde_registros: number;
  totalizar_por: 'vlr' | 'qtd';
  empresa_id: string;
}
interface EmpresaResponse {
  result: { empresa_id: string; uf: string; fantasia: string }[];
}
@Component({
  selector: 'app-divina-produtos-por-empresa',
  templateUrl: './divina-produtos-por-empresa.component.html',
  styleUrls: ['./divina-produtos-por-empresa.component.scss'],
})
export class DivinaProdutosPorEmpresaComponent implements OnInit {
  empresa: any = [];
  date = new Date();
  init_dash = false;
  endpoint = 'gen/rest/TSMSincronizar/';
  filtro_request: FiltroRequest = {
    data_inicio: this.date.toISOString().substring(0, 10),
    data_fim: this.date.toISOString().substring(0, 10),
    qtde_registros: 100,
    totalizar_por: 'vlr',
    empresa_id: null,
  };
  filtro = {
    dataInicio: this.date,
    dataFim: this.date,
    mesAtual: new Date().getMonth(),
    semanaAtual: 0,
    dia: 0,
    selecionado: 'DIA',
  };
  filter_options = [5, 10, 15, 20, 30, 50, 100];
  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    public _snackBar: MatSnackBar,
    public platform: Platform,
  ) {}

  ngOnInit() {}
  async ionViewWillEnter() {
    await this.getEmpresas();
    this.getRanking();
  }
  resultado: {
    codigo: string;
    descricao: string;
    categoria_descricao: string;
    quantidade_venda: string;
    custo_unitario: string;
    valor_venda_unitaria: string;
    total_custo: string;
    total_venda: string;
  }[] = [];
  async getRanking(event?) {
    this.init_dash = false;
    this.cdr.detectChanges();
    try {
      this.filtro_request.data_fim = this.datePipe.transform(
        this.filtro.dataFim,
        'yyyy-MM-dd'
      );
      this.filtro_request.data_inicio = this.datePipe.transform(
        this.filtro.dataInicio,
        'yyyy-MM-dd'
      );

      // ufs.forEach( e => this.resultado.push({uf:e.uf,produtos_qtd_total:[],produtos_valor_total:[]}));
      this.resultado = await this.getRankingProdutos();
    } catch (error) {

    }
    this.init_dash = true;
    this.cdr.detectChanges();
  }
  async getRankingProdutos() {
    return await this.api
      .post(this.endpoint + 'ListarRankingProdutos', this.filtro_request)
      .then((res) => {
        res.result.map((ab) => {
          ab.custo_unitario = Number(ab.custo_unitario.replace(',', '.'));
          ab.quantidade_venda = Number(ab.quantidade_venda.replace(',', '.'));
          ab.total_custo = Number(ab.total_custo.replace(',', '.'));
          ab.total_venda = Number(ab.total_venda.replace(',', '.'));
          ab.valor_venda_unitaria = Number(
            ab.valor_venda_unitaria.replace(',', '.')
          );
          return ab;
        });
        return res.result;
      }).catch(e => {
        this._snackBar.open("Houve um Error! Tente Novamente Mais Tarde");
      });
    //   async (response: any) => {

    // ).finally(() => {
    //   setTimeout(() => {
    //     this.init_dash = true;
    //     this.cdr.detectChanges();

    //   }, 200);
    // });
  }
  empresas = [];
  async getEmpresas() {
    return await this.api
      .get<EmpresaResponse>(this.endpoint + 'ListarEmpresas')
      .then((res) => {
        this.empresas = res.result;
        if (res.result.length > 0) {
          this.filtro_request.empresa_id = res.result[0].empresa_id;
        }
      });
    //   async (response: any) => {

    // ).finally(() => {
    //   setTimeout(() => {
    //     this.init_dash = true;
    //     this.cdr.detectChanges();

    //   }, 200);
    // });
  }
  returnPorcentage(qtd, total) {
    const sum = 100 / total;
    return (sum * qtd).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  }
  mesAtual = new Date().getMonth();

  proximo() {
    switch (this.filtro.selecionado) {
      case 'SEMANA':
        this.filtro.semanaAtual++;
        this.addSemana();
        break;
      case 'MES':
        this.filtro.mesAtual++;
        this.addMes();
        break;
      case 'DIA':
        this.filtro.dia++;
        this.addDia();
        break;
    }
  }

  anterior() {
    switch (this.filtro.selecionado) {
      case 'SEMANA':
        this.filtro.semanaAtual--;
        this.addSemana();
        break;
      case 'MES':
        this.filtro.mesAtual--;
        this.addMes();
        break;
      case 'DIA':
        this.filtro.dia--;
        this.addDia();
        break;
    }
  }

  addSemana() {
    this.filtro.selecionado = 'SEMANA';
    let inicio = moment().add(this.filtro.semanaAtual, 'weeks').day(0); // domingo desta semana
    let fim = moment().add(this.filtro.semanaAtual, 'weeks').day(6); // sábado desta semana
    this.filtro.dataInicio = new Date(inicio.toString());
    this.filtro.dataFim = new Date(fim.toString());
  }
  addMes() {
    this.filtro.selecionado = 'MES';
    let d = new Date();
    let anoC = d.getFullYear();
    let mesC = this.filtro.mesAtual;
    this.filtro.dataInicio = new Date(anoC, mesC, 1);
    this.filtro.dataFim = new Date(anoC, mesC + 1, 0);
  }
  addDia() {
    this.filtro.selecionado = 'DIA';
    let date = new Date();
    let emissaoDe = new Date();
    let emissaoAte = new Date();
    this.filtro.dataInicio = new Date(
      emissaoDe.setDate(date.getDate() + this.filtro.dia)
    );
    this.filtro.dataFim = new Date(
      emissaoAte.setDate(date.getDate() + this.filtro.dia)
    );
  }
  async exportexcel() {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const EXCEL_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'AbcVendas');


    if (
      (this.platform.is('mobile') ||
        this.platform.is('tablet') ||
        this.platform.is('ios') ||
        this.platform.is('android')) &&
      !this.platform.is('mobileweb') && !isWindows()
    ) {
      const excelBuffer: any = await XLSX.writeXLSX(wb, {
        bookType: 'xlsx',
        type: 'array',
      });
      const excelData: Blob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const base64:string = await convertBlobToBase64(excelData);

      Filesystem.requestPermissions();
      const result = await Filesystem.writeFile({
        path: `abc-venda-${
              snakeCase(String(this.empresas.find(
                (e) => e.empresa_id == this.filtro_request.empresa_id
              ).fantasia).toLowerCase())
            }-${this.filtro_request.data_inicio}_-_${
              this.filtro_request.data_fim
            }.xlsx`,
        data: base64,
        // directory: FilesystemDirectory.Documents,
        directory: Directory.Cache,

      }).then(async e => {
          await Share.share({
          text:"Teste TEXT",
          title: "teste TItle",
          url: e.uri
        })
      });
      // /// FUNCIONAL START
      // FileSharer.share({
      //     filename:  `abc-venda-${
      //       snakeCase(String(this.empresas.find(
      //         (e) => e.empresa_id == this.filtro_request.empresa_id
      //       ).fantasia).toLowerCase())
      //     }-${this.filtro_request.data_inicio}_-_${
      //       this.filtro_request.data_fim
      //     }.xlsx`,
      //     contentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //     // If you want to save base64:
      //     base64Data:base64.split(',')[1],

      //     // If you want to save a file from a path:
      //     // path: "../../file.pdf",
      // }).then(() => {
      //     // do sth
      // }).catch(error => {
      //     console.error("File sharing failed", error.message);
      // });
      // /// FUNCIONAL END

    } else {

       /* save to file */
        XLSX.writeFile(
          wb,
          `abc-venda-${
            snakeCase(this.empresas.find(
              (e) => e.empresa_id == this.filtro_request.empresa_id
            ).fantasia)
          }-${this.filtro_request.data_inicio}_-_${
            this.filtro_request.data_fim
          }.xlsx`
        );
    }
  }
}
