import { ApexOptions } from 'ng-apexcharts';
import { ChangeDetectorRef, Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { ApiService } from 'src/app/service/api.service';
import { Platform, ViewWillEnter } from '@ionic/angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { Moment } from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { Debounce } from '../decorators/debouce-decorator';
import { DrePipe } from './dre.pipe';
import { formarDRE } from '../utils';
interface ProdutoRequest {
  codigo: string;
  descricao: string;
  categoria_descricao: string;
  quantidade_venda: number;
  custo_unitario: number;
  valor_venda_unitaria: string;
  total_custo: number;
  total_venda: number;
}

interface FiltroRequest {
  data_inicio: string;
  data_fim: string;
  qtde_registros: number;
  usuario_id: string;
  totalizar_por: 'vlr' | 'qtd';
  uf: string;
}
interface UFResponse {
  result: { uf: string }[];
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-dre-faturamento',
  templateUrl: './dre-faturamento.component.html',
  styleUrls: ['./dre-faturamento.component.scss'],
  providers: [
    DrePipe,
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    // {
    //   provide: DateAdapter,
    //   useClass: MomentDateAdapter,
    //   deps: [ MAT_MOMENT_DATE_ADAPTER_OPTIONS_FACTORY],
    // },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class DREFaturamentoComponent implements OnInit, ViewWillEnter {
  empresa: any = [];
  init_dash = true;
  endpoint = 'gen/rest/TSMSincronizar/';
  date = new FormControl(moment());
  filter_options = [5, 10, 15, 20, 30, 50, 100];
  json_data = [
    {
      tipo: 'Receita',
      descricao: 'VENDA CUPOM-PDV',
      valor: 50,
    },
    {
      tipo: 'Despesa',
      descricao: 'DESPESA COM PESSOAL',
      valor: 15,
    },
    {
      tipo: 'Despesa',
      descricao: 'DESPESA COMERCIAIS',
      valor: 20,
    },
  ];

  dataArray = _.groupBy(this.json_data, 'tipo');
  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    public _snackBar: MatSnackBar,
    public platform: Platform,
    private drePipe: DrePipe
  ) {}

  ngOnInit() {

    this.getEmpresas();
  }
  ionViewWillEnter() {
    this.getEmpresas();

    // this.();
    // this.getTodas();
  }
  resultado: { uf: string; produtos_valor_total: any[]; produtos_qtd_total }[] =
    [];



  proximo() {
      this.date.setValue((this.date.value.add(1,'month')));
      this.getEmpresas();
  }

  anterior() {
      this.date.setValue((this.date.value.add(-1,'month')));
      this.getEmpresas();
  }
  apexChartOptions: ApexOptions = {
    chart: {
      height: 500,
      width: "100%",
      type: 'pie',
      animations: {
        speed: 400,
      },
    },
    labels: [],
    series: [],
    legend: {
      // position: 'bottom',
      width: 400,
      horizontalAlign: 'left',
      fontSize: '14px',
      offsetX: 0,
      itemMargin: {
        horizontal: 0,
        vertical: 0
      },
      formatter: (seriesName, opts) => {
        const round = (num, places) => {
          return +(parseFloat(num).toFixed(places));
        }
        // const class3w = (index) => {
        //   if (index % 2) {
        //     return '<span style="background-color:#dedede; padding: 10px 0; border-radius:2px; line-height: 2.5">';
        //   } else {
        //     return '<span >';
        //   }
        // }
        return `<span>${opts.w.globals.series[opts.seriesIndex].toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' }) + " | " + round(opts.w.globals.seriesPercent[opts.seriesIndex], 2) + '%' + " | " + seriesName}
                      </span>`
      },
    },
    title: {
      text: undefined,
      align: 'left',
      margin: 20,
      offsetX: 50,
      offsetY: 0,
      style: {
        fontSize: '25px',
        fontWeight: 'bold',
        fontFamily: 'inherit',
        color: '#303030'
      },
    },
    subtitle: {
      text: undefined,
      align: 'left',
      margin: 20,
      offsetX: 50,
      offsetY: 370,
      style: {
        fontSize: '18px',
        fontWeight: 'normal',
        fontFamily: 'inherit',
        color: '#303030'
      },
    },
    plotOptions: {
      pie: {
        customScale: 0.8,
        expandOnClick: true,
        offsetX: -100,
        offsetY: -70,
        dataLabels: {
          offset: -10
        },
      }
    },
    dataLabels: {
      style: {
        fontSize: '18px',
        fontFamily: 'Roboto, Arial, sans-serif',
        fontWeight: 'bold',
        colors: ['#ffffff']
      }
    },
    responsive: [
      {
        breakpoint: 1200,
        options: {
          plotOptions: {
            pie: {
              offsetX: 0,
            }
          },
          legend: {
            offsetX: 0,
          }
        }
      },
      {
        breakpoint: 1000,
        options: {
          chart: {
            height: 300,
            width: '100%',
          },
        }
      },
      {
        breakpoint: 800,
        options: {
          plotOptions: {
            pie: {
              customScale: 1,
              offsetX: 0,
              offsetY: 0,
            }
          },
          subtitle: {
            margin: 20,
            offsetX: 0,
            offsetY: 40,
          },
          title: {
            margin: 20,
            offsetX: 0,
            offsetY: 0,
          },
          chart: {
            height: 10000,
            width: "100%",
          },
          legend: {
            width: '100%',
            position: "bottom",
            fontSize: '14px',
            offsetX: -20,
            offsetY: 0,
          },
          dataLabels: {
            style: {
              fontSize: '14px',
            }
          }
        }
      },
    ],
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: 'dark',
      custom: ({ seriesIndex, w }): string => {
        return `<div class="flex items-center h-8 min-h-8 max-h-8 px-3">
              <div class="w-3 h-3 rounded-full" style="background-color: ${w.globals.colors[seriesIndex]};"></div>
              <div class="ml-2 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
              <div class="ml-2 text-md font-bold leading-none">${w.globals.series[seriesIndex].toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}</div>
            </div>`
      }
    }
  };
  cht_RECEITA_title = { ...this.apexChartOptions.title };
  cht_RECEITA_subtitle = { ... this.apexChartOptions.subtitle };
  cht_RECEITA_series = { ... this.apexChartOptions.series };
  cht_RECEITA_labels = { ... this.apexChartOptions.labels };
  cht_RECEITA_legend = { ... this.apexChartOptions.legend };
  cht_RECEITA_tooltip = { ... this.apexChartOptions.tooltip };
  cht_RECEITA_colors = { ... this.apexChartOptions.colors };

  cht_RECEITA_DESPESA_title = { ...this.apexChartOptions.title };
  cht_RECEITA_DESPESA_subtitle = { ... this.apexChartOptions.subtitle };
  cht_RECEITA_DESPESA_series = { ... this.apexChartOptions.series };
  cht_RECEITA_DESPESA_labels = { ... this.apexChartOptions.labels };
  cht_RECEITA_DESPESA_legend = { ... this.apexChartOptions.legend };
  cht_RECEITA_DESPESA_tooltip = { ... this.apexChartOptions.tooltip };
  cht_RECEITA_DESPESA_colors = { ... this.apexChartOptions.colors };

  cht_DESPESA_title = { ...this.apexChartOptions.title };
  cht_DESPESA_subtitle = { ... this.apexChartOptions.subtitle };
  cht_DESPESA_series = { ... this.apexChartOptions.series };
  cht_DESPESA_labels = { ... this.apexChartOptions.labels };
  cht_DESPESA_legend = { ... this.apexChartOptions.legend };
  cht_DESPESA_tooltip = { ... this.apexChartOptions.tooltip };
  cht_DESPESA_colors = { ... this.apexChartOptions.colors };
  empresas = [];
  @Debounce(250)
  async getEmpresas(event?) {
    this.empresas = [];
    this.init_dash = false;

    await this.api
      .post('pesquisarUsuarioEmpresa', { idusuario: localStorage.getItem('id') })
      .then(async (response: any) => {
        if (response) {
          let empresas = response.empresas.map( e=>e = JSON.parse(e));
          for (let i = 0; i < empresas.length; i++) {
            empresas[i].dres = await _.groupBy([...(await this.getDreEmpres(empresas[i].idempresa)), ...((await this.getDreEmpresLayout1(empresas[i].idempresa))?.filter(e => e.descricao.indexOf('CMV') == -1 ))], 'tipo');
            // empresas[i].dres = await _.groupBy(await this.getDreEmpres(empresas[i].idempresa), 'tipo');
            let dres_financeiro = (await this.getDreEmpresaFinanceiro(empresas[i].idempresa)).map( e => {
              return {...e, "forma_e_prazo": `${e.forma_pagamento_desc} - ${e.prazo_pagamento_desc}`}
            })
            // dres_financeiro.sort((a,b)=> a.forma_e_prazo.localeCompare(b.forma_e_prazo) ) // A-Z
            empresas[i].dres_financeiro = dres_financeiro;
            let grupo = await _.groupBy(dres_financeiro, 'forma_e_prazo');
            let element: any = empresas[i];

            let KEYS = Object.keys(grupo);
            //RECEITA
            let RECEITA = [];
            for (let index = 0; index < KEYS.length; index++) {
              const key = KEYS[index];
              RECEITA.push({
                numVendas: grupo[key]?.map(e => Number(e.valor_total)).reduce((b, a) => b + a, 0),
                label: grupo[key][0].forma_e_prazo,
              })
            }
            // for (let index = 0; index < element.dres_financeiro?.length; index++) {
            //   RECEITA.push({
            //     numVendas: element.dres_financeiro[index],
            //     label: element.dres_financeiro[index].forma_e_prazo,
            //   })
            // }
            RECEITA = RECEITA.sort((a: any, b: any) => { return Number(b.numVendas) - Number(a.numVendas) })
            element.numRECEITA = RECEITA.map(e => Number(e.numVendas));
            element.RECEITALabels = RECEITA.map(e => e.label);

            let subtitleRECEITA = { ... this.apexChartOptions.subtitle };
            subtitleRECEITA.text = 'Valor Total: ' + RECEITA.map(e => Number(e.numVendas)).reduce((partialSum, a) => partialSum + a, 0)
              .toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });
            element.subtitleRECEITA = subtitleRECEITA;
            this.cht_RECEITA_title.text =  "RECEITA";

            //DESPESA
            let DESPESA = [];
            for (let index = 0; index < element.dres.Despesa?.length; index++) {
              DESPESA.push({
                numVendas:Number( element.dres.Despesa[index].valor),
                label: element.dres.Despesa[index].descricao,
              })
            }

            DESPESA = DESPESA.sort((a: any, b: any) => { return Number(b.numVendas) - Number(a.numVendas) })
            element.numDESPESA = DESPESA.map(e => Number(e.numVendas));
            element.DESPESALabels = DESPESA.map(e => e.label);

            let subtitleDESPESA = { ... this.apexChartOptions.subtitle };
            subtitleDESPESA.text = 'Valor Total: ' + DESPESA.map(e => Number(e.numVendas)).reduce((partialSum, a) => partialSum + a, 0)
              .toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });

            element.subtitleDESPESA = subtitleDESPESA;
            this.cht_DESPESA_title.text =  "DESPESA";

            //RECEITA_DESPESA
            let RECEITA_DESPESA = [];
            // for (let index = 0; index < element.dres.Despesa?.length; index++) {
              RECEITA_DESPESA.push({
                numVendas: element.dres_financeiro?.map( e => e.valor_total).reduce( (a,b)=>Number(a)+Number(b),0)??0,
                label: 'RECEITA',
              })
              element.RECEITA_TOTAL = element.dres_financeiro?.map( e => e.valor_total).reduce( (a,b)=>Number(a)+Number(b),0)??0;
              element.DESPESA_TOTAL = element.dres?.Despesa?.map( e => e.valor).reduce( (a,b)=>Number(a)+Number(b),0)??0;

              if((element.RECEITA_TOTAL - element.DESPESA_TOTAL) > 0) {
                element.cht_RECEITA_DESPESA_colors =  ["#187e0c", "#dc3545"]
              } else {
                element.cht_RECEITA_DESPESA_colors =  ["#dc3545", "#187e0c"]
              }
              RECEITA_DESPESA.push({
                numVendas: element.dres?.Despesa?.map( e => e.valor).reduce( (a,b)=>Number(a)+Number(b),0)??0,
                label: 'DESPESA',
              })
            // }
            RECEITA_DESPESA = RECEITA_DESPESA.sort((a: any, b: any) => { return Number(b.numVendas) - Number(a.numVendas) })
            element.numRECEITA_DESPESA = RECEITA_DESPESA.map(e => Number(e.numVendas));
            element.RECEITA_DESPESALabels = RECEITA_DESPESA.map(e => e.label);

            let subtitleRECEITA_DESPESA = { ... this.apexChartOptions.subtitle };
            subtitleRECEITA_DESPESA.text = 'Total Movimentado: ' + RECEITA_DESPESA.map(e => Number(e.numVendas)).reduce((partialSum, a) => partialSum + a, 0)
              .toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });

            element.subtitleRECEITA_DESPESA = subtitleRECEITA_DESPESA;
            this.cht_RECEITA_DESPESA_title.text =  "RECEITA x DESPESA"
            this.cdr.detectChanges();
          }
          // this.empresas = empresas.filter(e=> e?.dres && (e?.dres?.Despesa?.length > 0 || e?.dres_financeiro?.length > 0));
          this.empresas = empresas.filter(e=> (e?.numDESPESA?.length > 0 || e?.numRECEITA?.length > 0));

          this.cdr.detectChanges();
          //RECEITA
          this.cht_RECEITA_colors = ["#ffbc58", "#01cccd", "#6610f2", "#dc3545", "#f11fea", "#187e0c", "#fee440", "#00bbf9", "#00f5d4", "#f15bb5", "#15616d", "#78290f", "#ff99c8", "#92e6a7"];

          // this.cht_RECEITA_tooltip.custom = ({ seriesIndex, w }): string => {
          //   return `<div class="flex items-center h-8 min-h-8 max-h-8 px-3">
          //                       <div class="w-3 h-3 rounded-full" style="background-color: ${w.globals.colors[seriesIndex]};"></div>
          //                       <div class="ml-2 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
          //                       <div class="ml-2 text-md font-bold leading-none">${w.globals.series[seriesIndex]}</div>
          //                     </div>`
          // };
          // this.cht_RECEITA_legend.formatter = (seriesName, opts) => {
          //   const round = (num, places) => {
          //     return +(parseFloat(num).toFixed(places));
          //   }
          //   return `<span>${opts.w.globals.series[opts.seriesIndex] + " | " + round(opts.w.globals.seriesPercent[opts.seriesIndex], 2) + '%' + " | " + seriesName}
          //               </span>`
          // };
          //DESPESA
          this.cht_DESPESA_colors = ["#ffbc58", "#01cccd", "#6610f2", "#dc3545", "#f11fea", "#187e0c", "#fee440", "#00bbf9", "#00f5d4", "#f15bb5", "#15616d", "#78290f", "#ff99c8", "#92e6a7"];
          //RECEITA_DESPESA
          // if(element.RECEITA_TOTA < ){

          //   this.cht_RECEITA_DESPESA_colors = ["#187e0c", "#dc3545"];
          // }

          // this.cht_DESPESA_tooltip.custom = ({ seriesIndex, w }): string => {
          //   return `<div class="flex items-center h-8 min-h-8 max-h-8 px-3">
          //                       <div class="w-3 h-3 rounded-full" style="background-color: ${w.globals.colors[seriesIndex]};"></div>
          //                       <div class="ml-2 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
          //                       <div class="ml-2 text-md font-bold leading-none">${w.globals.series[seriesIndex]}</div>
          //                     </div>`
          // };
          // this.cht_DESPESA_legend.formatter = (seriesName, opts) => {
          //   const round = (num, places) => {
          //     return +(parseFloat(num).toFixed(places));
          //   }
          //   return `<span>${opts.w.globals.series[opts.seriesIndex] + " | " + round(opts.w.globals.seriesPercent[opts.seriesIndex], 2) + '%' + " | " + seriesName}
          //               </span>`
          // };
          this.cdr.detectChanges();
          // ================valor total==============
          // const sumValorTotal = response.formaPgto.map(e => Number(e.valorTotal)).reduce((partialSum, a) => partialSum + a, 0);
          // let cht_total = response.formaPgto.sort((a: any, b: any) => { return Number(b.valorTotal) - Number(a.valorTotal) });
          // this.cht_total_series = cht_total.map(e => Number(e.valorTotal));
          // this.cht_total_labels = cht_total.map(e => e.formaPgto);
          // this.cht_total_title.text = 'Valor Total';
          // this.cht_total_subtitle.text = 'Valor Total = ' + sumValorTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });
          console.log(empresas)

        }
      }).finally(() => {
        setTimeout(() => {
          this.init_dash = true;
          this.cdr.detectChanges();
          event?.target.complete();
        }, 200);
      });

  }


  async getDreEmpres(empresa_id){
    let dados =  await this.api.get('listaDRE', {csw_id:empresa_id, data: this.date.value.format('MM-yyyy'), layout: 2});
    return formarDRE(dados)
  }
  async getDreEmpresLayout1(empresa_id){

    let dados = await this.api.get('listaDRE', {csw_id:empresa_id, data: this.date.value.format('MM-yyyy'), layout: 1})

    return formarDRE(dados)
  }
  async getDreEmpresaFinanceiro(empresa_id){

    return await this.api.get('venda-financeiro/listar', {empresa_csw:empresa_id, data_inicio: this.date.value.startOf('month').format('YYYY-MM-DD'),
      data_fim: this.date.value.endOf('month').format('YYYY-MM-DD')})
  }


  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    this.getEmpresas();
    datepicker.close();
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }
}
